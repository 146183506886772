import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ReduxDetector from "i18next-redux-languagedetector";
import Backend from "i18next-chained-backend";
import Fetch from "i18next-fetch-backend";

const Detector = new LanguageDetector();
Detector.addDetector(ReduxDetector);

export default function configureI18n({ i18nextConfig, redux }) {
    //let language_complete = navigator.language.split("-");
    //let language = language_complete[0] || "de";
    const language =
        document
            .getElementById("f2x-ooh-contact-app")
            .getAttribute("data-current-lang") || "de";
    i18n.use(Backend)
        .use(Detector)
        .use(initReactI18next)
        .init({
            lng: language,
            backend: {
                backends: [Fetch],
                backendOptions: [
                    {
                        loadPath:
                            process.env.NODE_ENV !== "production"
                                ? "/locales/{{lng}}.json"
                                : "/wp-content/themes/f2x/f2x-ooh-contact-app/public/locales/{{lng}}.json",
                    },
                ],
            },
            detection: {
                order: ["navigator"],
                lookupRedux: redux.lookupRedux,
                cacheUserLanguageRedux: redux.cacheUserLanguageRedux,
                caches: ["redux"],
                excludeCacheFor: ["cimode"],
            },
            whitelist: i18nextConfig.whitelist,
            fallbackLng: i18nextConfig.fallbackLng,
            ns: i18nextConfig.ns,
            defaultNS: i18nextConfig.defaultNS,
            debug: process.env.NODE_ENV !== "production",
            interpolation: {
                escapeValue: false,
            },
            react: {
                useSuspense: true,
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ["br", "strong", "i", "span"],
            },
            nonExplicitWhitelist: true,
            load: "currentOnly",
        });

    return i18n;
}
