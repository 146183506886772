import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import axios from "axios";
import { showThanks } from "../actions/app";
import { FaSpinner } from "react-icons/fa";

import Select from "react-select";

class HubspotContactForm extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
        showThanks: PropTypes.func.isRequired,
    };

    state = {
        region: null,
        portalId: null,
        formId: null,
        formData: {
            company: "",
            salutation: "",
            firstname: "",
            lastname: "",
            email: "",
        },
        formLegal: {
            consent_subscription: false,
            //consent_processing: false,
        },
        formDataRequired: [
            "company",
            "salutation",
            "firstname",
            "lastname",
            "email",
            "consent_subscription",
            /* "consent_processing",*/
        ],
        hubspotFieldMapping: {
            company: "company",
            salutation: "salutation",
            firstname: "firstname",
            lastname: "lastname",
            email: "email",
            consent_subscription: "consent_subscription",
            //consent_processing: "consent_processing",
        },
        formDataDone: [],
        formValidated: false,
        formHasErrors: false,
        formErrors: [],
        isRequesting: false,
    };
    componentDidMount() {
        const appElement = document.getElementById("f2x-ooh-contact-app");
        if (appElement) {
            const portalId =
                appElement.getAttribute("data-hubspot-portal-id") || null;
            const formId =
                appElement.getAttribute("data-hubspot-form-id") || null;

            this.setState({
                portalId,
                formId,
            });

            /*
            window.setTimeout(() => {
                window.scrollTo({
                    top:
                        appElement.getBoundingClientRect().top +
                        window.scrollY -
                        64,
                    behavior: "smooth",
                });
            }, 250);
            */
        }
    }

    onFormSubmit(e) {
        e.preventDefault();
        const {
            portalId,
            formId,
            formData,
            formLegal,
            formErrors,
            hubspotFieldMapping,
        } = this.state;
        const { showThanks } = this.props;

        //validate
        let isValid = true;
        Object.keys(formLegal).forEach((key) => {
            if (!formLegal[key]) {
                formErrors.push(key);
            }
        });

        if (formErrors.length > 0) {
            this.setState({ formValidated: true, formErrors });
            isValid = false;
        }

        //submit to hupspot
        if (isValid) {
            const postFields = [];

            Object.keys(formData).forEach((key) => {
                postFields.push({
                    name: hubspotFieldMapping[key],
                    value: formData[key],
                });
            });
            const postData = {
                portalId,
                formId,
                submittedAt: Date.now(),
                fields: postFields,
                context: {
                    pageName: document.title,
                    pageUri: window.location.href,
                },
                legalConsentOptions: {
                    consent: {
                        consentToProcess: true,
                        text: "I agree to allow F2X to store and process my personal data.",
                        communications: [
                            {
                                value: true,
                                subscriptionTypeId: 999,
                                text: "I agree to receive marketing communications F2X.",
                            },
                        ],
                    },
                },
            };
            this.setState({ isRequesting: true, formValidated: false });

            axios
                .post(
                    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
                    { ...postData },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    const formErrors = [];
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        response.data.errors.length > 0
                    ) {
                        response.data.errors.forEach((item) => {
                            switch (item.errorType) {
                                case "INVALID_METADATA":
                                    formErrors.push("general");
                                    break;
                                case "REQUIRED_FIELD":
                                    {
                                        const fieldName =
                                            item.message.match(/'([^']+)'/)[1];
                                        const fieldKey = Object.keys(
                                            hubspotFieldMapping
                                        ).find(
                                            (key) =>
                                                hubspotFieldMapping[key] ===
                                                fieldName
                                        );
                                        formErrors.push(fieldKey);
                                    }
                                    break;
                            }
                        });
                    }

                    if (formErrors.length > 0) {
                        this.setState({
                            formHasErrors: true,
                            formErrors: formErrors,
                            isRequesting: false,
                        });
                    } else {
                        let dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            event: "formSubmission",
                            eventCategory: "OOH Kampagne",
                            eventAction: "Submit",
                            eventLabel: formId,
                        });

                        this.setState({
                            formHasErrors: false,
                            formErrors: [],
                            isRequesting: false,
                        });
                        showThanks();
                    }
                })
                .catch((error) => {
                    const formErrors = [];
                    console.log(error);
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.errors &&
                        error.response.data.errors.length > 0
                    ) {
                        error.response.data.errors.forEach((item) => {
                            switch (item.errorType) {
                                case "INVALID_METADATA":
                                    formErrors.push("general");
                                    break;
                                case "REQUIRED_FIELD":
                                    {
                                        const fieldName =
                                            item.message.match(/'([^']+)'/)[1];
                                        const fieldKey = Object.keys(
                                            hubspotFieldMapping
                                        ).find(
                                            (key) =>
                                                hubspotFieldMapping[key] ===
                                                fieldName
                                        );
                                        formErrors.push(fieldKey);
                                    }
                                    break;
                            }
                        });
                    }

                    if (formErrors.length > 0) {
                        this.setState({
                            formHasErrors: true,
                            formErrors: formErrors,
                            isRequesting: false,
                        });
                    }
                });
        }
    }
    onFormInputChange(e) {
        const { formData, formErrors, formDataDone } = this.state;
        this.setState(
            {
                formErrors: formErrors.filter((item) => item !== e.target.name),
                formData: { ...formData, [e.target.name]: e.target.value },
                formDataDone: [
                    ...formDataDone.filter((item) => item !== e.target.name),
                    e.target.name,
                ],
            },
            () => {
                this.onFormInputValidate(e.target.name, e.target.value);
            }
        );
    }

    onFormSelectChange(key, e) {
        const { formData, formErrors, formDataDone } = this.state;
        this.setState(
            {
                formErrors: formErrors.filter((item) => item !== key),
                formData: { ...formData, [key]: e.value },
                formDataDone: [
                    ...formDataDone.filter((item) => item !== key),
                    key,
                ],
            },
            () => {
                this.onFormInputValidate(key, e.value);
            }
        );
    }

    onFormLegalCheckboxChange(e) {
        const { formLegal, formErrors, formDataDone } = this.state;
        this.setState(
            {
                formErrors: formErrors.filter((item) => item !== e.target.name),
                formLegal: { ...formLegal, [e.target.name]: e.target.checked },
                formDataDone: [
                    ...formDataDone.filter((item) => item !== e.target.name),
                    e.target.name,
                ],
            },
            () => {
                this.onFormInputValidate(e.target.name, e.target.checked);
            }
        );
    }

    onFormInputValidate(name, value) {
        const { formErrors } = this.state;
        switch (name) {
            case "company":
            case "firstname":
            case "lastname":
            case "ust_id":
                if (value.length < 1) formErrors.push(name);
                if (value.length > 0) {
                    const stringRegex =
                        /^([()\sa-zA-Z0-9-.,€sêéèàáäöüÄÖÜß/&\p{L}\p{M}])*$/u;
                    if (!stringRegex.test(value)) formErrors.push(name);
                }
                break;
            case "salutation":
                if (value.length < 1) formErrors.push(name);
                break;
            case "phone":
                {
                    const phoneRegex = /^(\+|0)(?:[0-9] ?){7,20}[0-9]$/;
                    if (!phoneRegex.test(value)) formErrors.push(name);
                }
                break;
            case "email":
                {
                    const emailRegex =
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                    if (!emailRegex.test(value)) formErrors.push("email");
                }
                break;
            case "consent_subscription":
                /*case "consent_processing":*/
                {
                    if (!value) formErrors.push(name);
                }
                break;
        }
        this.setState(
            {
                formErrors: formErrors,
            },
            this.onFormValidate
        );
    }
    onFormValidate() {
        const { formErrors, formDataRequired, formDataDone } = this.state;
        this.setState({
            formHasErrors: formErrors.length > 0,
            formValidated: formDataRequired.length === formDataDone.length,
        });
    }

    getValidateClasses(field) {
        const { formValidated, formHasErrors, formErrors } = this.state;
        let classes = "";
        if ((formValidated || formHasErrors) && formErrors.includes(field)) {
            classes = "is-invalid";
        }
        if (formValidated && !formHasErrors && !formErrors.includes(field)) {
            classes = "is-valid";
        }
        return classes;
    }

    render() {
        const { t } = this.props;
        const {
            region,
            portalId,
            formId,
            formData,
            formErrors,
            formValidated,
            formHasErrors,
            isRequesting,
        } = this.state;
        if ((!region, !portalId, !formId)) return null;

        const indicatorSeparator = () => (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.481"
                height="4.277"
                viewBox="0 0 7.481 4.277"
            >
                <path
                    id="Icon_ionic-ios-arrow-forward"
                    data-name="Icon ionic-ios-arrow-forward"
                    d="M2.988,3.739.157.91a.532.532,0,0,1,0-.755.539.539,0,0,1,.757,0L4.122,3.361a.534.534,0,0,1,.016.737L.917,7.326A.535.535,0,0,1,.159,6.57Z"
                    transform="translate(7.481) rotate(90)"
                    fill="#fff"
                />
            </svg>
        );
        const optionsSalutation = t("app.form.salutation.options", {
            returnObjects: true,
        });
        const currentSalutation = optionsSalutation.filter(
            (m) => m.value === formData.salutation
        );

        return (
            <div
                className="f2x-contact-app-form f2x-ooh-contact-app-form"
                data-validated={formValidated}
                data-has-errors={formHasErrors}
            >
                <div className="f2x-ooh-contact-app-form-wrap">
                    <div
                        className="f2x-contact-app-form-group"
                        data-field="company"
                    >
                        <label>
                            {t("app.form.company.label")} <sup>*</sup>
                        </label>
                        <input
                            name="company"
                            type="text"
                            value={formData.company}
                            onChange={(e) => this.onFormInputChange(e)}
                            inputMode="text"
                            autoComplete="organization"
                            className={
                                `f2x-contact-app-form-control ` +
                                this.getValidateClasses("company")
                            }
                        />
                        {(formValidated || formHasErrors) &&
                            formErrors.includes("company") && (
                                <div className="f2x-contact-app-form-control-feedback">
                                    {t("app.form.company.feedback")}
                                </div>
                            )}
                    </div>
                    <div
                        className="f2x-contact-app-form-group"
                        data-field="salutation"
                    >
                        <label>
                            {t("app.form.salutation.label")} <sup>*</sup>
                        </label>

                        <Select
                            options={optionsSalutation}
                            value={
                                currentSalutation.length > 0
                                    ? currentSalutation
                                    : null
                            }
                            placeholder={t("app.form.salutation.placeholder")}
                            onChange={(e) =>
                                this.onFormSelectChange("salutation", e)
                            }
                            className={
                                `f2x-react-select-container react-select-container ` +
                                this.getValidateClasses("salutation")
                            }
                            classNamePrefix="f2x-react-select react-select"
                            styles={{
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none",
                                }),
                            }}
                            components={{
                                DropdownIndicator: indicatorSeparator,
                            }}
                            isSearchable={false}
                        />
                        {(formValidated || formHasErrors) &&
                            formErrors.includes("salutation") && (
                                <div className="f2x-contact-app-form-control-feedback">
                                    {t("app.form.salutation.feedback")}
                                </div>
                            )}
                    </div>
                    <div
                        className="f2x-contact-app-form-group"
                        data-field="firstname"
                    >
                        <label>
                            {t("app.form.firstname.label")} <sup>*</sup>
                        </label>
                        <input
                            name="firstname"
                            type="text"
                            value={formData.firstname}
                            onChange={(e) => this.onFormInputChange(e)}
                            inputMode="text"
                            autoComplete="given-name"
                            className={
                                `f2x-contact-app-form-control ` +
                                this.getValidateClasses("firstname")
                            }
                        />
                        {(formValidated || formHasErrors) &&
                            formErrors.includes("firstname") && (
                                <div className="f2x-contact-app-form-control-feedback">
                                    {t("app.form.firstname.feedback")}
                                </div>
                            )}
                    </div>
                    <div
                        className="f2x-contact-app-form-group"
                        data-field="lastname"
                    >
                        <label>
                            {t("app.form.lastname.label")} <sup>*</sup>
                        </label>
                        <input
                            name="lastname"
                            type="text"
                            value={formData.lastname}
                            onChange={(e) => this.onFormInputChange(e)}
                            required
                            inputMode="text"
                            autoComplete="family-name"
                            className={
                                `f2x-contact-app-form-control ` +
                                this.getValidateClasses("lastname")
                            }
                        />
                        {(formValidated || formHasErrors) &&
                            formErrors.includes("lastname") && (
                                <div className="f2x-contact-app-form-control-feedback">
                                    {t("app.form.lastname.feedback")}
                                </div>
                            )}
                    </div>
                    <div
                        className="f2x-contact-app-form-group"
                        data-field="email"
                    >
                        <label>
                            {t("app.form.email.label")} <sup>*</sup>
                        </label>
                        <input
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={(e) => this.onFormInputChange(e)}
                            required
                            inputMode="email"
                            autoComplete="email"
                            className={
                                `f2x-contact-app-form-control ` +
                                this.getValidateClasses("email")
                            }
                        />
                        {(formValidated || formHasErrors) &&
                            formErrors.includes("email") && (
                                <div className="f2x-contact-app-form-control-feedback">
                                    {t("app.form.email.feedback")}
                                </div>
                            )}
                    </div>
                    
                    <div
                        className={
                            `f2x-contact-app-form-group-checkbox ` +
                            this.getValidateClasses("consent_subscription")
                        }
                    >
                        <label>
                            <input
                                type="checkbox"
                                name="consent_subscription"
                                onChange={(e) =>
                                    this.onFormLegalCheckboxChange(e)
                                }
                            />
                            <span className="f2x-contact-app-form-group-checkbox-check"></span>
                            <span
                                className="f2x-contact-app-form-group-checkbox-label"
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        "app.form.legal_consent_subscription.label"
                                    ),
                                }}
                            />
                        </label>
                        {(formValidated || formHasErrors) &&
                            formErrors.includes("consent_subscription") && (
                                <div className="f2x-contact-app-form-control-feedback">
                                    {t(
                                        "app.form.legal_consent_subscription.feedback"
                                    )}
                                </div>
                            )}
                    </div>

                    <div className="f2x-contact-app-form-info">
                        {t("app.form.info.required")}
                    </div>
                </div>

                <div className="f2x-contact-app-form-submit">
                    {(formValidated || formHasErrors) &&
                        formErrors.includes("general") && (
                            <div className="f2x-contact-app-form-general-feedback">
                                {t("app.form.general.feedback")}
                            </div>
                        )}
                    <button
                        type="button"
                        className="f2x-form-btn"
                        disabled={
                            !formValidated || formHasErrors || isRequesting
                        }
                        onClick={(e) => this.onFormSubmit(e)}
                    >
                        {isRequesting && (
                            <span className="f2x-contact-btn-spinner-wrap">
                                <FaSpinner
                                    icon="spinner"
                                    className="f2x-contact-btn-spinner"
                                    title={t("app.form.button.loading")}
                                />
                            </span>
                        )}
                        <span>{t("app.form.button.label")}</span>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ app: state.app });
const mapDispatchToProps = (dispatch) => ({
    showThanks: () => dispatch(showThanks()),
});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(HubspotContactForm);
