import {
    APP_LOADED,
    APP_FORM_SHOW,
    APP_FORM_SHOW_THANKS,
} from "./types";

export const setAppLoaded = () => (dispatch) => {
    dispatch({
        type: APP_LOADED,
    });
    return true;
};

export const showForm = () => (dispatch) => {
    dispatch({
        type: APP_FORM_SHOW,
    });
    return true;
};

export const showThanks = () => (dispatch) => {
    dispatch({
        type: APP_FORM_SHOW_THANKS,
    });
    return true;
};
