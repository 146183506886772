import {
    APP_LOADED,
    APP_FORM_SHOW,
    APP_FORM_SHOW_THANKS,
} from "../../actions/types";

const initialState = {
    loaded: false,
    showForm: false,
    showThanks: false,
};

export default function (state = initialState, action) {
    const { type } = action;

    switch (type) {
        case APP_LOADED:
            return {
                ...state,
                loaded: true,
                showForm: true,
                //showThanks: true,
            };        
        case APP_FORM_SHOW:
            return {
                ...state,
                showForm: true,
            };
        case APP_FORM_SHOW_THANKS:
            return {
                ...state,
                showThanks: true,
            };
        default:
            return state;
    }
}
